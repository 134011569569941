<template>
  <div>
    <b-modal id="modal-5" title="Basic Modal" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <validation-observer ref="simpleRules">
        <b-form>

          <b-form-group label="Unsur" label-for="Unsur">
            <validation-provider #default="{ errors }" rules="required" name="Unsur">
              <v-select v-model="input.kode_1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="loadKode2(input.kode_1.id_kode_1)" :state="errors.length > 0 ? false : null" placeholder="Unsur"
                label="uraian" :options="kode_1" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Bidang" label-for="Bidang">
            <validation-provider #default="{ errors }" rules="required" name="Bidang">
              <v-select v-model="input.kode_2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="loadKode3(input.kode_2.id_kode_2)" :state="errors.length > 0 ? false : null" placeholder="Bidang"
                label="uraian" :options="kode_2" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Kegiatan" label-for="Kegiatan">
            <validation-provider #default="{ errors }" rules="required" name="Kegiatan">
              <v-select v-model="input.kode_3" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="loadKode4(input.kode_3.id_kode_3)" :state="errors.length > 0 ? false : null"
                placeholder="Kegiatan" label="uraian" :options="kode_3" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Sub Kegatan" label-for="Sub Kegiatan">
            <validation-provider #default="{ errors }" rules="required" name="Sub Kegiatan">
              <v-select v-model="input.kode_4" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null" placeholder="Sub Kegiatan" label="uraian" :options="kode_4" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Anggaran" label-for="anggarn">
            <validation-provider #default="{ errors }" rules="required" name="Anggaran Objek Belanja">
              <currency-input v-model="input.anggaran" class="form-control" :state="errors.length > 0 ? false : null"
                placeholder="Rp. "></currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button variant="primary" type="submit" @click.prevent="validationForm">
            <feather-icon icon="SaveIcon" size='15' /> Simpan
          </b-button>
          <b-button variant="warning" type="submit" @click="close()" class="ml-1">
            <feather-icon icon="XIcon" size='15' /> Close
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
import { BModal, BButton, VBModal, BAlert, BFormInput, BFormGroup, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from '@/views/input/data_kontrak/componet/CurrencyInput.vue'

export default {
  components: {
    BButton, ValidationProvider, ValidationObserver, BFormInput, BFormGroup, BRow, BCol,
    BModal,
    BAlert, vSelect,
    CurrencyInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      input: {},
      kode_2: [],
      kode_3: [],
      kode_4: []

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    async loadKode2(id_kode_1) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/belanja/kode_2',
        data: {
          id_kode_1: id_kode_1
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kode_2 = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadKode3(id_kode_2) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/belanja/kode_3',
        data: {
          id_kode_2: id_kode_2
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kode_3 = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadKode4(id_kode_3) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/belanja/kode_4',
        data: {
          id_kode_3: id_kode_3
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kode_4 = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    emits: ['simpanObjek'],
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('simpanObjek', this.input)
          this.close();
        }
      })
    },

    close() {
      this.$root.$emit('bv::hide::modal', 'modal-5')
      this.input = {
        kode_1: {},
        kode_2: {},
        kode_3: {},
        kode_4: {}
      }
    }
  },
  props: {
    kode_1: {},
    id_pagu_belanja: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>